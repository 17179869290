<template>
    <v-container fluid>
        <v-overlay :value="loading" :opacity="0.35" color="#d3d3d3">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-row>
            <v-col cols="12">
                <v-card>
                    <v-card-text class="py-0">
                        <div>
                            <v-row>
                                <v-col class="py-0">
                                    <v-data-table :headers="activeHeaders" :sort-by="['custom']"
                                        :custom-sort="customSort" :items="tablePositions" hide-default-footer
                                        class="elevation-1" mobile-breakpoint="0" dense>

                                        <template v-slot:item.position="{ index }">
                                            <h6 class="ma-0 font-weight-medium">
                                                {{ index + 1 }}
                                            </h6>
                                        </template>

                                        <template v-slot:item.points="{ item }">
                                            <h6 class="ma-0 font-weight-medium">
                                                {{ item.points }}
                                            </h6>
                                        </template>

                                        <template v-slot:item.pointsDifferenceFor="{ item }">
                                            <h6 class="ma-0">
                                                {{ item.pointsDifferenceFor ? item.pointsDifferenceFor : '-' }}
                                            </h6>
                                        </template>

                                        <template v-slot:item.pointsDifferenceAgainst="{ item }">
                                            <h6 class="ma-0">
                                                {{ item.pointsDifferenceAgainst ? item.pointsDifferenceAgainst : '-' }}
                                            </h6>
                                        </template>

                                        <template v-slot:item.played="{ item }">
                                            <h6 class="ma-0">
                                                {{ item.win + item.loss + item.draw }}
                                            </h6>
                                        </template>

                                        <template v-slot:item.pointsDiff="{ item }">
                                            <h6 class="ma-0">
                                                {{ item.pointsDifferenceFor - item.pointsDifferenceAgainst }}
                                            </h6>
                                        </template>

                                        <template v-slot:item.teamEnt.name="{ item }">
                                            <div style="display: flex;" class="cursor-pointer"
                                                @click="goToProfile(item.teamEnt.id)">
                                                <div>
                                                    <v-img :src="`/assets/gaa/teams/icons/${item.teamEnt.code}.WEBP`"
                                                        contain max-width="30" class="mr-2 img_border"></v-img>
                                                </div>
                                                <div @click="goToProfile(item.teamEnt.id)">{{ item.teamEnt.name }}</div>
                                            </div>
                                        </template>
                                    </v-data-table>
                                    <v-row dense><v-col class="d-flex align-center justify-center my-1">
                                            <v-btn small @click="clearPermutations" :disabled="predictions.length == 0"
                                                class="mr-2">Clear</v-btn>
                                            <v-btn class="secondary" small @click="copyPermutationToClipboard"
                                                :disabled="predictions.length == 0">Share</v-btn>
                                        </v-col></v-row>
                                </v-col>
                            </v-row>
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row class="scrollable-row">
            <v-col cols="12" class="py-0">
                <v-card>
                    <v-card-text class="py-0">
                        <div>
                            <v-row>
                                <v-col cols="12">
                                    <v-data-iterator :items="fixtureList" hide-default-footer>
                                        <template v-slot:default="props">
                                            <v-row justify="center" :key="reload">
                                                <v-col v-for="(item, index) in props.items" :key="index" cols="12"
                                                    lg="8" class="pa-0 mb-2">
                                                    <list-card-row-permutation :id="`match-${index}`"
                                                        :ref="`match-${index}`" :fixture="item"
                                                        :progressbar-color="item.progressColor" footer-btn="CHAT"
                                                        :prediction="sharedPredictions.find(prediction => prediction.fixtureId == item.id)"
                                                        footer-btn-color="default" :editing="true"
                                                        footer-btn-two="PROFILE" footer-btn-color-two="default"
                                                        class="fixture" @marginSelected="selectionMade(index);"
                                                        @marginSelection="marginSelectionMade"
                                                        @resultUnselected="undoSelection(index)"
                                                        @marginUnselected="undoMargin"
                                                        @resultSelected="resultSelected" />
                                                </v-col>
                                            </v-row>
                                        </template>
                                    </v-data-iterator>
                                </v-col>
                                <v-snackbar v-model="snackbar">
                                    {{ snackbarText }}
                                    <template v-slot:action="{ attrs }">
                                        <v-btn plain text v-bind="attrs" @click="snackbar = false">
                                            Close
                                        </v-btn>
                                    </template>
                                </v-snackbar>
                            </v-row>
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import ChartCard from '@/components/card/ChartCard'
import ListCardRowPermutation from '@/components/card/listCard/ListCardRowPermutation'
import { deepCopyFunction } from '@/utils/arrayUtils.js'
import { mapActions, mapGetters } from 'vuex'

export default {
    props: ['stage'],
    components: {
        ChartCard,
        ListCardRowPermutation
    },
    metaInfo: {
      // title will be injected into parent titleTemplate
      title: 'Permutations',
    },
    data() {
        return {
            reload: false,
            loading: true,
            roundRobinHeaders: [
                { text: '', value: 'position', sortable: true },
                { text: 'Team', value: 'teamEnt.name', sortable: true },
                { text: 'P', value: 'matchday', sortable: true },
                { text: 'Win', value: 'win', sortable: true },
                { text: 'Loss', value: 'loss', sortable: true },
                { text: 'Draw', value: 'draw', sortable: true },
                { text: '+/-', value: 'pointsDiff', sortable: true },
                { text: 'Points', value: 'points', sortable: true },
            ],
            knockoutHeaders: [
                {
                    text: '',
                    align: 'start',
                    sortable: false,
                    value: 'homeLogo',
                },
                { text: 'Home', value: 'home' },
                { text: '', value: 'time' },

                { text: 'Away', value: 'away' },
                {
                    text: '',
                    align: 'start',
                    sortable: false,
                    value: 'awayLogo',
                },
                { text: 'Date', value: 'date' },
            ],
            teams: [],
            predictions: [],
            sharedPredictions: [],
            standings: [],
            snackbar: false,
            snackbarText: 'Predictions copied to clipboard'
        }
    },
    computed: {
        ...mapGetters(["loggedInUser", "getFixtures", "getCurrentGameweek", "getCurrentStandings"]),
        title() {
            let tournment = this.stage.charAt(0).toUpperCase() + this.stage.slice(1);
            if (true) {
                return tournment + " Senior Hurling Championship";
            }
        },
        competitionRounds() {
            return [...[...this.getFixtures.reduce((set, fixture) => set.add(fixture.round), new Set())]].filter(it => it != "Round Robin").sort();
        },
        tablePositions() {
            let currentStandings = [];
            currentStandings = this.calculateStandings();
            return currentStandings;
        },
        fixtureList() {
            let fixturesFromDb = [...this.getFixtures.filter(it => it.stage.toUpperCase() == this.stage.toUpperCase() && it.matchdayLabel.toUpperCase() != 'FINAL')];
            for (let i = 0; i < fixturesFromDb.length; i++) {
                fixturesFromDb[i].matchResult = null;
                fixturesFromDb[i].homeGoals = null;
                fixturesFromDb[i].homePoints = null;
                fixturesFromDb[i].awayGoals = null;
                fixturesFromDb[i].awayPoints = null;
            }
            return fixturesFromDb;
        },
        activeHeaders() {
            if (this.$vuetify.breakpoint.xsOnly) {
                this.roundRobinHeaders.find(it => it.value == "points").text = "Pts";
                return this.roundRobinHeaders.filter(it => it.value != 'win' & it.value != 'loss' & it.value != 'draw' & it.value != 'position');
            } else {
                return this.roundRobinHeaders;
            }
        }
    },
    methods: {
        ...mapActions(["fetchFixtures", "fetchCompetitionStandingsForWeek", "fetchFixtures"]),
        goToProfile(id) {
            this.$router.push(`/app/hurling/statistics/profile/${id}`)
        },
        calculateStandings(excludedTeam){
            let currentStandings = deepCopyFunction([...this.getCurrentStandings]);
            if (currentStandings.length == 0 || this.getFixtures.length == 0) return;
            for (let i = 0; i < this.predictions.length; i++) {
                let prediction = this.predictions[i];
                let fixture = this.getFixtures.find(it => it.id == prediction.fixtureId);
                if(excludedTeam && (fixture.homeTeam == excludedTeam || fixture.awayTeam == excludedTeam)) return;
                let homeTeamPerformance = currentStandings.find(it => it.teamEnt.name == fixture.homeTeam);
                let awayTeamPerformance = currentStandings.find(it => it.teamEnt.name == fixture.awayTeam);
                homeTeamPerformance.matchday += 1;
                awayTeamPerformance.matchday += 1;
                if (prediction.result == 1) {
                    homeTeamPerformance.points += 2;
                    homeTeamPerformance.win += 1;
                    awayTeamPerformance.loss += 1;
                    if (prediction.margin) {
                        homeTeamPerformance.pointsDifferenceFor += Number(prediction.margin);
                        awayTeamPerformance.pointsDifferenceAgainst += Number(prediction.margin);
                    }
                } else if (prediction.result == 2) {
                    awayTeamPerformance.points += 2;
                    homeTeamPerformance.loss += 1;
                    awayTeamPerformance.win += 1;
                    if (prediction.margin) {
                        homeTeamPerformance.pointsDifferenceAgainst += Number(prediction.margin);
                        awayTeamPerformance.pointsDifferenceFor += Number(prediction.margin);
                    }
                } else if (prediction.result == -1) {
                    homeTeamPerformance.points += 1;
                    awayTeamPerformance.points += 1;
                    homeTeamPerformance.draw += 1;
                    awayTeamPerformance.draw += 1;
                }
            }
            return currentStandings;
        },
        populateQueryParams() {
            const params = new URLSearchParams(window.location.search);
            const fixtures = params.getAll('fixtures[]');
            const margins = params.getAll('margin[]');
            const results = params.getAll('result[]');

            // Group them together
            const combined = fixtures.map((fixture, index) => ({
                fixtureId: fixture,
                margin: margins[index],
                result: results[index]
            }));
            this.sharedPredictions = combined;
        },
        matchTimeString(item) {
            return item.matchResult ? item.homeGoals + ":" + item.homePoints + ' - ' + item.awayGoals + ":" + item.awayPoints : item.time
        },
        formatDate(date) {
            return date.split('-').reverse().map((part, index) => index === 2 ? part.slice(-2) : part).join('/');
        },
        formatDateUS(date) {
            return date.replace(/(\d+)\/(\d+)\/(\d{2})(\d{2})/, (_, m, d, __, y) => `${d.padStart(2, '0')}/${m.padStart(2, '0')}/${y}`);
        },
        selectionMade(index) {
            if (index < this.fixtureList.length - 1) {
                document.getElementById(`match-${index + 1}`).scrollIntoView({ behavior: 'smooth', block: 'start' })
            }
        },
        undoSelection(result) {
            const existingIndex = this.predictions.findIndex(prediction => prediction.fixtureId === result.fixtureId);
            this.predictions.pop(existingIndex);
        },
        undoMargin(result) {
            const existingIndex = this.predictions.findIndex(prediction => prediction.fixtureId === result.fixtureId);
            this.$set(this.predictions, existingIndex, {
                ...this.predictions[existingIndex],
                margin: null
            });
        },
        marginSelectionMade(margin) {
            let index = this.predictions.findIndex(it => it.fixtureId == margin.fixtureId);
            this.$set(this.predictions, index, {
                ...this.predictions[index],
                margin: margin.margin
            });
        },
        resultSelected(result) {
            const existingIndex = this.predictions.findIndex(prediction => prediction.fixtureId === result.fixtureId);
            if (existingIndex !== -1) {
                this.$set(this.predictions, existingIndex, result);
            } else {
                this.predictions.push(result);
            }
        },
        customSort(items, sortBy, sortDesc) {
            items.sort((a, b) => {
                // A) Compare points: higher points come first
                if (a.points !== b.points) {
                    return sortDesc[0] ? a.points - b.points : b.points - a.points;
                }

                // Find all items with the same points
                const samePointsItems = items.filter(item => item.points === a.points);

                // Only perform head-to-head comparison if there are exactly 2 items with the same points
                if (samePointsItems.length === 2) {
                    // B) If points are the same, compare head-to-head record

                    const headToHeadComparison = this.compareHeadToHead(a, b);
                    if (headToHeadComparison !== 0) {
                        return headToHeadComparison;
                    }
                }

                // C) If points and head-to-head are the same, compare score difference
                return sortDesc[0] ? (a.pointsDifferenceFor - a.pointsDifferenceAgainst) - (b.pointsDifferenceFor - b.pointsDifferenceAgainst) : (b.pointsDifferenceFor - b.pointsDifferenceAgainst) - (a.pointsDifferenceFor - a.pointsDifferenceAgainst);
            });

            return items;
        },
        compareHeadToHead(teamA, teamB) {
            let fixture = this.getFixtures.find(it => (it.homeTeam == teamA.teamEnt.name && it.awayTeam == teamB.teamEnt.name) || it.homeTeam == teamB.teamEnt.name && it.awayTeam == teamA.teamEnt.name);
            let fixtureResult = null;
            if (fixture && fixture.matchResult == null) {
                let prediction = this.predictions.find(it => it.fixtureId == fixture.id)
                if (prediction) {
                    fixtureResult = prediction.result;
                }
            } else {
                if (fixture) {
                    fixtureResult = fixture.matchResult;
                }
            }
            if (fixtureResult == "HOME_WIN" || fixtureResult == 1) {
                return 1;
            } else if (fixtureResult == "AWAY_WIN" || fixtureResult == 2) {
                return -1;
            } else {
                return 0;
            }
        },
        clearPermutations() {
            this.predictions = [];
            this.reload = !this.reload;
        },
        copyPermutationToClipboard() {
            // Step 1: Build the base URL
            const url = new URL(window.location.href);

            // Step 2: Define your query parameters
            const query = {
                fixtures: this.predictions.map(item => item.fixtureId),
                margin: this.predictions.map(item => item.margin),
                result: this.predictions.map(item => item.result)
            };

            // Step 3: Append each parameter as an array
            Object.keys(query).forEach(key => {
                query[key].forEach(value => {
                    url.searchParams.append(`${key}[]`, value);
                });
            });

            // Step 4: Copy the URL to the clipboard
            navigator.clipboard.writeText(url.toString())
                .then(() => {
                    this.snackbarText = 'Predictions copied to clipboard';
                    this.snackbar = true;
                })
                .catch(err => {
                    this.snackbarText = 'Could not copy predictions';
                    this.snackbar = true;
                });

        }
    },
    async mounted() {
        this.fetchFixtures(this.$store.getters.getCompetitionId);
        let resp = await this.fetchCompetitionStandingsForWeek({ "competitionId": this.$store.getters.getCompetitionId, "name": this.stage.toUpperCase(), "gameweek": this.getCurrentGameweek - 1 });
        this.loading = false;
        this.populateQueryParams();
    },
    watch: {
        predictions(){
            if(this.predictions.length == this.fixtureList.length){
                console.log(this.calculateStandings(this.tablePositions[4].teamEnt.name))
            }
        }
    }
}
</script>

<style lang="scss" scoped>
::v-deep .v-data-table-header th .v-icon {
    display: none;
}

/* Ensure the headers still look clickable */
.v-data-table-header th {
    cursor: pointer;
}

.img_border {
    border-style: solid;
    border-width: 2px;
}

.custom-card-title {
    word-break: keep-all !important;
    white-space: normal !important;
}

.scrollable-row {
    height: 40vh;
    overflow-y: auto;
    margin-top: 6px;
    /* Space between the fixed row and scrollable row */
}

.v-row:first-of-type {
    position: sticky;
    top: 0;
    background-color: white;
    /* Ensure background is visible when scrolling */
    z-index: 1;
    /* Keep it above the scrolling content */
}
</style>